import React, { useState } from 'react';
import { render } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faUndo } from '@fortawesome/free-solid-svg-icons';
import uuid from 'react-uuid'

import Produto from "./components/Produto";

import './style.css';

function getProduto(){
  return { valor: 0, quantidade: 0, melhor: false, id: uuid()};
}

function getProdutosInitialState(){
  return [
    getProduto(),
    getProduto()
  ]
}

const App = () => {
  const [produtos, setProdutos] = useState(getProdutosInitialState());

  function atualizarProduto(id, quantidade, valor){
    console.log(`Atualizar produto: ${id} ${quantidade} ${valor}`)

    quantidade = parseFloat(quantidade);
    if(!quantidade){ 
      quantidade = 0; 
    }
    
    valor = parseFloat(valor);
    if(!valor){
      valor = 0;
    }

    let novoProdutos = produtos.map(p => ({
      valor: (p.id === id ? valor : p.valor),
      quantidade: (p.id === id ? quantidade : p.quantidade),
      melhor: false,
      id: p.id
    }));

    console.log("Novo produtos:", novoProdutos);

    let produtosChecagem = novoProdutos.filter(produto => produto.valor != 0 && produto.quantidade != 0);

    console.log("Produtos para comparação:",produtosChecagem);

    if(produtosChecagem.length > 1){
      let melhorCusto = Number.MAX_VALUE;
      let melhoresProdutos = [];

      produtosChecagem.forEach(produto => {
        const custoBeneficio = produto.valor/produto.quantidade;
        console.log("Custo/benefício:", custoBeneficio);

        if(custoBeneficio < melhorCusto){
          melhorCusto = custoBeneficio;
          melhoresProdutos = [produto.id];
        }
        else if(custoBeneficio === melhorCusto){
          melhoresProdutos.push(produto.id);
        }

        console.log("Melhores produtos:", melhoresProdutos);
      });

      if(melhoresProdutos.length > 0){
        novoProdutos.filter(produto => melhoresProdutos.indexOf(produto.id) !== -1)
                    .forEach(produto => {produto.melhor = true;});
      }
    }

    console.log("Novo produtos final:", novoProdutos);

    setProdutos(novoProdutos);
  }

  function adicionarProduto(event){
    event.preventDefault();
    event.stopPropagation();

    const novoProdutos = [...produtos, getProduto()];

    setProdutos(novoProdutos);
  }

  function removerProduto(id){
    const novoProdutos = produtos.filter(produto => produto.id !== id);

    setProdutos(novoProdutos);
  }

  function limparProdutos(event){
    event.preventDefault();
    event.stopPropagation();
    
    setProdutos(getProdutosInitialState());
  }

  return (
    <div className="containerApp">
      <h1>Melhor custo-benefício</h1>
      <p className="instrucao">Deseja saber qual produto tem o menor custo por unidade/quantidade? Insira os valores abaixo e compare:</p>

      {produtos.map(produto => (<Produto key={produto.id} onChange={atualizarProduto} onRemove={removerProduto} exibirRemover={produtos.length > 2} {...produto} />))}

      <div className="containerBotoes">
        <a href="#" className="botao" onClick={adicionarProduto}>
          <span>Adicionar</span>
          <FontAwesomeIcon icon={faEdit} className="icone" />
        </a>
        <a href="#" className="botao" onClick={limparProdutos}>
          <span>Limpar</span>
          <FontAwesomeIcon icon={faUndo} className="icone" />
        </a>
      </div>
    </div>
  );
}

render(<App />, document.getElementById('root'));
