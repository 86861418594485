import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "./Produto.css";

const Produto = (props) => {
  const { onChange, onRemove } = props;

  const [produto, setProduto] = useState({valor: props.valor, quantidade: props.quantidade});

  function handleOnChange(event){
    const { name, value } = event.target;
    setProduto({ ...produto, [name]: value });
    console.log(value);
  }

  function handleOnBlur(){
    console.log(produto);
    onChange(props.id, produto.quantidade, produto.valor);
  }

  return (
    <div className={"produto" + (props.melhor ? " melhor" : "")}>
      {props.exibirRemover &&  <FontAwesomeIcon className="fechar" icon={faTimes} onClick={() => onRemove(props.id)} />
      }
      <label htmlFor={"valor-" + props.id}>Valor:</label>
      <input id={"valor-" + props.id} name="valor" type="number" onChange={handleOnChange} onBlur={handleOnBlur} />
      <label htmlFor={"quantidade-" + props.id}>Quantidade:</label>
      <input id={"quantidade-" + props.id} name="quantidade" type="number" onChange={handleOnChange} onBlur={handleOnBlur} />
    </div>
  );
};

export default Produto;